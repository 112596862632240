import React, { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from "./components/Header";
import { Slider } from "./components/Slider";
import { LocationProvider } from "./components/LocationProvider";
import { Login } from "./components/Login";
import { PrivateRoute } from "./components/PrivateRoute";
import { NotFound } from "./components/NotFound";
import { UserList } from "./components/UserList";

export const App: FC = () => {
  return (
    <BrowserRouter>
      <LocationProvider>
        <div className="App">
          {/*<BannerUploader/>*/}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              index
              element={
                <PrivateRoute element={
                  <>
                    <Header />
                    <Slider />
                    <UserList />
                  </>
                }/>
              }
            />
            <Route path="*" element={<PrivateRoute element={<NotFound />} />} />
          </Routes>
        </div>
      </LocationProvider>
    </BrowserRouter>
  );
};

