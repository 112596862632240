import styles from './UserList.module.scss';
import {IFilters, IFiltersAll, IUser} from "../../app/store/users/user.type";
import { FC, useState, useEffect, useCallback, useRef, useMemo } from "react";
import { NotFound } from "../NotFound";
import { User } from "../User";
import { Filters } from "../Filters";
import { ThreeDots } from "react-loader-spinner";
import { InputFocusProvider } from "../Filters/InputFocusContext";
import {TG} from "../../functions/global";
import {useDispatch, useSelector} from "react-redux";
import {TypeRootState} from "../../app/store";
import {usersActions} from "../../app/store/users/users.slice";
import {Modal} from "../Modal";
import {UserProfile} from "../UserProfile";

export const UserList: FC = () => {
  const dispatch = useDispatch();
  const {storeScrollY, storeOffset, storeSearch, storeBlogTheme, storeCities, storeSubscribersRanges, storGender, storeSkills} = useSelector((state: TypeRootState) => state.users);
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [search, setSearch] = useState(storeSearch);
  const [cities, setCities] = useState<IFilters[]>(storeCities);
  const [blogTheme, setBlogTheme] = useState<IFilters[]>(storeBlogTheme);

  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const openProfile = (user: IUser) => {
    setSelectedUser(user);
    setIsProfileOpen(true);
    TG.BackButton.show()
    document.body.style.overflow = "hidden";
  };

  const handleBackButtonClick = () => {
    setIsProfileOpen(false);
    setSelectedUser(null);
    TG.BackButton.hide()
    document.body.style.overflow = "";
  }
  TG.onEvent('backButtonClicked', handleBackButtonClick)

  // @ts-ignore
  window.handleBackButtonClick = () => {
    handleBackButtonClick()
  }


  const [subscribersRanges, setSubscribersRanges] = useState<IFilters[]>(storeSubscribersRanges);
  const [gender, setGender] = useState<IFilters[]>(storGender);
  const [skills, setSkills] = useState<IFilters[]>(storeSkills);

  const [filters, setFilters] = useState<IFiltersAll>({
    cities: [],
    blogTheme: [],
    subscribersRanges: [],
    gender: [],
    skills: []
  });


  const [scrollLoading, setScrollLoading] = useState(false);
  const [page, setPage] = useState(storeOffset || 1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 50;

  const pageRef = useRef(page);
  const scrollRef = useRef(0);
  const isInitializedRef = useRef(false);

  const restoreScrollPosition = () => {
    if (isInitializedRef.current && storeScrollY !== undefined) {
      setTimeout(()=>{
        if (window.scrollY !== storeScrollY){
          window.scrollTo(0, storeScrollY);
        }
      },50)
    }
  };

  useEffect(() => {
    // Вызов restoreScrollPosition только после того, как данные были подгружены и страница инициализирована
    if (!isInitializedRef.current) {
      requestAnimationFrame(restoreScrollPosition);
      isInitializedRef.current = true;
    }
  }, [storeScrollY]);


  useEffect(() => {
    const handleScroll = () => {
      if (loading || loadingMore) return; // Если идет загрузка, не делать повторный запрос

      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollPosition + 100 >= documentHeight) {
        handleLoadMore();
        setScrollLoading(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Удаление обработчика при размонтировании компонента или после завершения загрузки
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, loadingMore, scrollLoading]);




  useEffect(() => {
    TG.BackButton.hide();
  }, []);

  // Синхронизация изменений локального состояния с store
  useEffect(() => {
    const handleScroll = () => {
      dispatch(usersActions.setScrollY(window.scrollY));
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(usersActions.setOffset(page));
  }, [page, dispatch]);

  useEffect(() => {
    dispatch(usersActions.setSearch(search));
  }, [search, dispatch]);

  useEffect(() => {
    dispatch(usersActions.setBlogTheme(blogTheme));
  }, [blogTheme, dispatch]);

  useEffect(() => {
    dispatch(usersActions.setCities(cities));
  }, [cities, dispatch]);

  useEffect(() => {
    dispatch(usersActions.setSubscribersRanges(subscribersRanges));
  }, [subscribersRanges, dispatch]);

  useEffect(() => {
    dispatch(usersActions.setGender(gender));
  }, [gender, dispatch]);

  useEffect(() => {
    dispatch(usersActions.setSkills(skills));
  }, [skills, dispatch]);

  // Мемоизация параметров поиска
  const searchParams = useMemo(() => {
    const params = new URLSearchParams();
    if (search) params.append("search", search);

    if (cities.length > 0) {
      const citiesValues = cities.map((c: IFilters) => c.value).join(",");
      params.append("cities", citiesValues);
    }

    if (blogTheme.length > 0) {
      const blogThemeValues = blogTheme.map((o: IFilters) => o.value).join(",");
      params.append("blogTheme", blogThemeValues);
    }

    // Добавление новых фильтров в параметры запроса
    if (subscribersRanges.length > 0) {
      const subscribersValues = subscribersRanges.map((o: IFilters) => o.value).join(",");
      params.append("subscribersRanges", subscribersValues);
    }

    if (gender.length > 0) {
      const genderValues = gender.map((o: IFilters) => o.value).join(",");
      params.append("gender", genderValues);
    }

    if (skills.length > 0) {
      const skillsValues = skills.map((o: IFilters) => o.value).join(",");
      params.append("skills", skillsValues);
    }

    params.append("limit", `${limit}`);
    params.append("offset", ((page - 1) * limit).toString());

    return params.toString();
  }, [search, cities, blogTheme, subscribersRanges, gender, skills, page]);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    setLoadingMore(pageRef.current > 1);

    try {
      const response = await fetch(`${(process.env.REACT_APP_BACKEND_URL || "")}/api/users?${searchParams}`);
      const data = await response.json();

      const totalPages = Math.ceil(data.total / limit);
      setTotalPages(totalPages);
      setFilters(data.filters || []);

      // Сохраняем старый список и новые данные
      const newUsers = data.users || [];
      setUsers(prevUsers => {
        if (pageRef.current === 1 || page === 1) {
          return newUsers;
        } else {
          return [...prevUsers, ...newUsers];
        }
      });
    } catch (error) {
      console.error("Ошибка при запросе данных пользователей", error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
      setScrollLoading(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isInitializedRef.current) {
      fetchUsers();
      isInitializedRef.current = true;
    } else {
      fetchUsers();
    }
  }, [fetchUsers]);

  const handleLoadMore = () => {
    if (pageRef.current < totalPages) {
      pageRef.current += 1;
      scrollRef.current = window.scrollY;
      setPage(pageRef.current);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);
    scrollRef.current = window.scrollY;
    pageRef.current = 1;
    setPage(pageRef.current);
  };

  const handleCitiesChange = (cities: IFilters[]) => {
    if (isInitializedRef.current) setCities(cities);
    scrollRef.current = window.scrollY;
    pageRef.current = 1;
    setPage(pageRef.current);
  };

  const handleBlogThemeChange = (blogTheme: IFilters[]) => {
    if (isInitializedRef.current) setBlogTheme(blogTheme);
    scrollRef.current = window.scrollY;
    pageRef.current = 1;
    setPage(pageRef.current);
  };

  // Обработчики для новых фильтров
  const handleSubscribersRangesChange = (ranges: IFilters[]) => {
    if (isInitializedRef.current) setSubscribersRanges(ranges);
    scrollRef.current = window.scrollY;
    pageRef.current = 1;
    setPage(pageRef.current);
  };

  const handleGenderChange = (gender: IFilters[]) => {
    if (isInitializedRef.current) setGender(gender);
    scrollRef.current = window.scrollY;
    pageRef.current = 1;
    setPage(pageRef.current);
  };

  const handleSkillsChange = (skills: IFilters[]) => {
    if (isInitializedRef.current) setSkills(skills);
    scrollRef.current = window.scrollY;
    pageRef.current = 1;
    setPage(pageRef.current);
  };

  return (
    <>
      <InputFocusProvider>
        <Filters
          value={{search, cities, blogTheme, subscribersRanges, gender, skills}}
          params={filters}
          onSearchChange={handleSearchChange}
          onCitiesChange={handleCitiesChange}
          onBlogThemeChange={handleBlogThemeChange}
          onSubscribersRangesChange={handleSubscribersRangesChange}
          onGenderChange={handleGenderChange}
          onSkillsChange={handleSkillsChange}
        />
      </InputFocusProvider>

      {loading && pageRef.current === 1 ? (
        <ThreeDots height="50" width="50" radius="9" color="#1864e7" wrapperClass="loader"/>
      ) : (
        <>
          {users.length === 0 ? <NotFound/> : (
            <div className={styles.users_list}>
              {users.map((user: IUser) => (
                <User key={user.id} user={user} onClick={() => openProfile(user)}/>
              ))}
            </div>
          )}
          {selectedUser && isProfileOpen &&
            <UserProfile user={selectedUser} />}
        </>
      )}

      {loadingMore && (
        <div className={styles.loader_more}>
          <ThreeDots height="30" width="30" radius="9" color="#1864e7" wrapperClass="loader"/>
        </div>
      )}
    </>
  );
};
